// Composables
import { useAppStore } from '@/store/app';
import { useAuthStore } from '@/store/auth';
import { Component } from 'vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import i18n from '@/plugins/v18n';

/**
 * A helper function to wrap the loading of a component with a global loading indicator
 * @param load The async component factory
 * @returns The loaded component
 */
async function wrapLoad<T extends Component>(load: () => PromiseLike<T> | T, delay = 300): Promise<T> {
  const store = useAppStore();
  try {
    store.globalLoading = true;
    const now = new Date();
    const result = await load();
    const elapsed = new Date().getTime() - now.getTime();
    if (elapsed < delay) {
      await new Promise((resolve) => setTimeout(resolve, delay - elapsed));
    }
    return result;
  } finally {
    store.globalLoading = false;
  }
}

const routes: RouteRecordRaw[] = [
  // Auth
  {
    name: 'authenticated',
    path: '/authenticated',
    component: () => import('@/views/EmptyView.vue'),
    meta: { requiresAuth: true },
    beforeEnter: async (to, from) => {
      if (to.query.redirect) {
        return (to.query.redirect as string) || '/';
      } else {
        return '/';
      }
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/auth/LoginForm.vue'),
    beforeEnter: async (to) => {
      if (await useAuthStore().checkAuth()) {
        return (to.query.redirect as string) || '/';
      }
    },
  },

  {
    path: '/reset-password',
    children: [
      { path: '', name: 'reset-password', component: () => import('@/views/auth/ResetPassword.vue') },
      {
        path: 'success',
        name: 'reset-password-success',
        component: () => import('@/views/auth/ResetPasswordSuccess.vue'),
      },
    ],
  },

  // Onboarding
  {
    name: 'signup',
    path: '/signup',
    alias: '/welcome',
    component: () => import('@/views/onboarding/Signup.vue'),
    beforeEnter: async () => {
      useAuthStore().logout();
    },
  },
  {
    name: 'signup-success',
    path: '/signup/success',
    props: (route) => route.query,
    component: () => import('@/views/onboarding/SignupSuccess.vue'),
  },
  {
    name: 'confirm',
    path: '/confirm',
    props: (route) => route.query,
    component: () => import('@/views/onboarding/ConfirmUser.vue'),
  },
  {
    name: 'confirm-success',
    path: '/confirm/success',
    component: () => import('@/views/onboarding/ConfirmUserSuccess.vue'),
  },
  {
    name: 'confirm-user-error',
    path: '/confirm/error/:error',
    component: () => import('@/views/onboarding/ConfirmUserError.vue'),
    props: (route) => ({ ...route.params, ...route.query }),
  },

  {
    path: '/',
    redirect: '/settings',
    component: () => import('@/layouts/MainTabbedView.vue'),
    children: [
      {
        path: 'settings',
        name: 'settings',
        meta: { requiresAuth: true },
        component: () => import('@/views/settings/layouts/SideNavBar.vue'),
        redirect: '/settings/company-profile',
        children: [
          {
            path: 'company-profile',
            name: 'company-profile',
            component: () => wrapLoad(() => import('@/views/settings/CompanyProfile.vue')),
          },
          {
            path: 'user-profile',
            name: 'user-profile',
            component: () => wrapLoad(() => import('@/views/settings/UserProfile.vue')),
          },
          {
            path: 'conditions',
            name: 'conditions',
            component: () =>
              wrapLoad(() => import('@/views/settings/projectConditionPresets/ProjectConditionPresets.vue')),
            children: [
              {
                path: ':id([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12})',
                name: 'condition-preset',
                props: true,
                component: () => import('@/views/settings/projectConditionPresets/ProjectConditionPreset.vue'),
              },
            ],
          },
          {
            path: 'custom-elements',
            name: 'custom-elements',
            component: () => wrapLoad(() => import('@/views/settings/CustomElements.vue')),
          },
          {
            path: 'room-element-presets',
            name: 'room-element-presets',
            component: () => wrapLoad(() => import('@/views/settings/RoomElementPresets.vue')),
          },
        ],
      },
      {
        name: 'templates',
        path: 'templates',
        meta: { requiresAuth: true },
        component: () => wrapLoad(() => import('@/views/templates/Templates.vue')),
        children: [
          {
            name: 'template',
            path: ':id',
            props: true,
            component: () => import('@/views/templates/Template.vue'),
          },
        ],
      },
      {
        path: 'integrations',
        name: 'integrations',
        meta: { requiresAuth: true },
        component: () => wrapLoad(() => import('@/views/integrations/Integrations.vue')),
        children: [
          {
            path: 'integration/:id',
            props: true,
            component: () => import('@/views/integrations/Integration.vue'),
          },
        ],
      },
      {
        path: '/:pathMatch(.*)*',
        redirect: '/',
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  if (!to.meta?.requiresAuth) return;
  const authStore = useAuthStore();
  const isLoggedIn = await authStore.checkAuth();
  if (!isLoggedIn) {
    return { name: 'login', query: { redirect: to.fullPath } };
  }
});

// router.beforeEach((to) => {
//   if (typeof to.query?.lang === 'string') {
//     // if (i18n.global.availableLocales.includes(to.query.lang)) i18n.global.locale.value = to.query.lang;
//     // else i18n.global.locale.value = i18n.global.fallbackLocale.value;
//     if (i18n.global.availableLocales.includes(to.query.lang)) localStorage.setItem('language', to.query.lang);
//     i18n.global.locale.value = to.query.lang;
//     const newQuery = { ...to.query };
//     delete newQuery.lang;
//     return { ...to, query: newQuery };
//   }
//   return;
// });

export default router;
